import React, { useState } from 'react';
import Header from './pages/Header';
import Typewriter from 'typewriter-effect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faYoutube, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Home = () => {
  
  const [activeImage, setActiveImage] = useState(null);

  const openLightbox = (image) => {
    setActiveImage(image);
  };

  const closeLightbox = () => {
    setActiveImage(null);
  };

    const images = [
        "/images/pic1.jpg",
        "/images/pic2.jpg",
        "/images/pic3.jpg",
        "/images/test.jpg",
        "/images/pic6.jpg",
        "/images/pic9.jpg",
        "/images/pic20.jpeg",
        "/images/pic8.jpg",
        // Add paths to your images here
      ];
    
  return (
    <div style={{backgroundColor:'#f9f999'}}>
      <Header />
      <h1 dir='rtl' style={{ color: 'black' }}>
  <Typewriter
    options={{
      strings: ['כיף שקפצתם לבקר<br />מחכה לחגוג איתכם'],
      autoStart: true,
      loop: true,
      typeSpeed: 5, // Speed of typing: lower numbers are faster
      deleteSpeed: 5, // Speed of deleting: lower numbers are faster
    }}
  />
</h1>

<div className="text-image-section">
      <img src="/images/pic10.jpg" alt="Descriptive Alt Text" />
                <div className="section-text">
                   {/* <Typewriter
    options={{
      strings: ['אהלן חברים כאן שי יום טוב! אני מופיע למעלה מ10 שנים, והייתי האומן הצעיר ביותר במועדוני הסטנדאפ בארץ(קאמל קומדי קלאב, סטנדאפ פקטורי, קומדי בר).קטע הסטנדאפ הכי מוכר שלי התפרסם בזכות חיקוי מדוייק שעשיתי על הכדורגלן ליאו מסי ואפילו הצליח להפוך לויראלי והגיע ללמעלה מעשרה מליון צופים ברחבי העולם!! האהבה שלי היא לייצר תוכן ולהצחיק אנשים אני מייצר תכני רשת ומערכונים בכל הפלטפורמות. אני מאוד מאמין בליצור תכנים נקיים,, כדי שכולם בכל הגילאים יוכלו ליהנות מהיצירה שלי. ואיך אני ברשתות?! אז הינה קצת במספרים: *למעלה מרבע מליון עוקבים בטיק טוק! * כ40 אלף עוקבים באינסטגרם! פייסבוק עם 31 אלף עוקבים ומה עם היוטיוב?! 100 אלף מנויים!! נעבור קצת לדברים שעשיתי... השתפפתי בתור שופט בתחרות טיק טוק של הבנים והבנות בערוץ הילדים, באולפן ליגת האלופות בערוץ הספורט, בתור פרשן טיק טוק בתוכנית "נקסט" בערוץ 13, ובתפקיד דרמתי בסדרת הדרמה "פאנץ", "צומת מילר" ואפילו עשיתי פינה מצחיקה ברדיו לב המדינה. אז לסיכום: סטנדאפיסט - יש!, יוצר תוכן - יש!, שחקן - יש!, כוכב רשת -לגמרי יש!, לוליין קרקס ומומחה מדעי המוח עם תואר מאוניברסיטת הרווארד - טוב זה פחות אני...'],
      autoStart: true,
      loop: true,
      typeSpeed: 5, // Speed of typing: lower numbers are faster
      deleteSpeed: 5, // Speed of deleting: lower numbers are faster
    }}
  /> */}
                  
                    <p style={{fontSize:'18px', color:'white' , backgroundColor:'black'}} dir='rtl'>שי יום טוב, כוכב הרשת, מכוכבי הטיקטוק הגדולים בישראל ולו מאות אלפי עוקבים, בעל וותק רב במופעים לילדים ונוער בכל רחבי הארץ

השתתף בתוכניות רבות כמו פאנץ', הבנים והבנות, ערוץ טדי מארח, צומת מילר ועוד....
במופע קומי ובידור שמותאם כולו לילדים ונוער.<br/>

בסוף המופע שי נשאר לעשות עם הקהל סרטוני ברכות, תמונות, סלפי וכו... עד אחרון הילדים.<br/>
אז נתראה באירוע הבא שלכם?😉</p>
                </div>
              
            </div>
     

        <div className="text-section">
                <p style={{fontSize:"22px", fontWeight: 'bold', textDecoration:'underline'}}>מההופעות האחרונות</p>
            </div>
        
        
        <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-item" onClick={() => openLightbox(image)}>
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
      {activeImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="lightbox-close">&times;</span>
          <img className="lightbox-image" src={activeImage} alt="Enlarged" />
        </div>
      )}

      <div className="video-container">
        <iframe 
         
          src="https://www.youtube.com/embed/g1A9S5kEVnA" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
        </div>

            <div dir='rtl' className="moving-text-section">
                <p className="moving-text"> ליצירת קשר </p>
                <br></br>
                <a style={{color:'black'}}href="https://wa.me/972545344267">054-534-4267</a> {/* Replace with your actual phone number */}
            </div>

            <footer className="footer">
            <div className="phone-number">
    <a style={{color:'white'}} href="https://wa.me/972545344267">054-534-4267</a> {/* Replace with your actual phone number */}
  </div>
        <div className="social-links">
          <a href="https://www.tiktok.com/@shaiyomtov?_t=8j1Vfx3pSN8&_r=1" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} size="2x" />
          </a>
          <a href="https://www.youtube.com/@ShaiYomTovTV" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
          <a href="https://wa.me/972545344267" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          
        </div>
      </footer>

            

      
        
      
    </div>
  );
};

export default Home;