import React from 'react';
import './Header.css'; // Ensure this path is correct

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        {/* Note how the image is referenced here */}
        <img src="/images/logo.png" alt="Logo" />
      </div>
      <nav>
        <ul className="nav-links">
          {/* Navigation items */}
          <li><a href="/">דף הבית</a></li>
       
        </ul>
      </nav>
    </header>
  );
};

export default Header;
